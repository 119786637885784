import { Component } from 'vue-property-decorator'
import { QDialog } from 'quasar'
import BaseSignUpMixin from '../areas/notLoggedIn/mixins/baseSignUpMixin'

@Component({
  components: {
    AgreeToMarketingCheckbox: () => import('./AgreeToMarketingCheckbox.vue')
  }
})
export default class AgreeToOAuthTermsDialog extends BaseSignUpMixin {
  get dialog (): QDialog {
    return this.$refs.agreeToTerms as QDialog
  }

  show () {
    this.dialog.show()
  }

  hide () {
    this.dialog.hide()
  }

  onDialogHide () {
    this.$emit('hide')
  }

  cancel () {
    this.hide()
  }

  async confirm () {
    this.$emit('ok')
    this.hide()
  }
}
